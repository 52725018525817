import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import {IPaginator} from "@/contracts/i-paginator";
import {ISpecShareMessageRequest} from "@/entities/specs/spec-share-message-entity";
import {ISpecSubmissionRequest} from "@/entities/specs/spec-submission-entity";
import {SpecShareEntity } from "@/entities/specs/spec-share-entity";
import SpecEntity, {SpecCreateBaseEntity} from "@/entities/specs/spec-entity";
import {
  convertStatusCountForDisplay,
  ShareList, ShareResponse
} from "@/repositories/spec/company/spec-share-repository";
import ObjectUtils from "@/utils/object-utils";

export default class SpecShareForSubmitterRepository extends RepositoryBase<any> {
  public constructor(companyId) {
    super();
    this.endpoint = `spec/companies/${companyId}/for-submitter`
  }

  public async findSpecShare(specShareId:number): Promise<SpecShareEntity> {
    const res = await this.get(this.endpoint + '/' + specShareId);
    return new SpecShareEntity(res.data);
  }

  public async list(query, statuses:string[], receiverId:string|number|null|undefined, params:IFindOption): Promise<ShareResponse> {
    const p = Object.assign({_as_pagination: true, q:query, statuses:statuses, receiverId}, params);
    const res = await this.get(this.endpoint + '/submissions', p);
    return {
      counts: convertStatusCountForDisplay(res.data.counts),
      data: this.toPagination(res, ShareList)
    }
  }

  public async listCompaniesForSubmission(query, params:IFindOption): Promise<IPaginator<ListCompaniesForSubmission>> {
    const p = Object.assign({_as_pagination: true, q:query}, params);
    const res = await this.get(this.endpoint + '/submission-companies', p);
    return this.toPagination(res, ListCompaniesForSubmission) as IPaginator<ListCompaniesForSubmission>;
  }

  public async saveSpecSubmissionWithSelectingSpec(submission:ISpecSubmissionRequest, specId:number) {
    const res = await this.post(this.endpoint + `/${submission.specShareId}/submission/with-select-spec/${specId}`, {submission});
    return res.data;
  }
  public async saveSpecSubmissionWithCreatingSpec(submission:ISpecSubmissionRequest, spec:SpecCreateBaseEntity) {
    const res = await this.post(this.endpoint + `/${submission.specShareId}/submission/with-create-spec`, {submission, spec});
    return res.data;
  }
  public async saveSpecSubmissionWithUpdatingSpec(submission:ISpecSubmissionRequest, specId: number, spec:SpecCreateBaseEntity) {
    const res = await this.post(this.endpoint + `/${submission.specShareId}/submission/with-update-spec/${specId}`, {submission, spec});
    return res.data;
  }

  public async setStatusAsRequestDeclined (specShareId:number, message: ISpecShareMessageRequest) {
    await this.put(this.endpoint + '/' + specShareId + '/decline-request', {message});
  }

  public async findReceiver(receiverId:number|string): Promise<{id:number, name:string}> {
    const res = await this.get(this.endpoint + '/receivers/' + receiverId);
    return res.data;
  }
}

export class ListCompaniesForSubmission {
  public companyId!:number;
  public companyName!:string;

  public acceptedCount!:number;
  public submittedCount!:number;
  public requestedCount!:number;
  public rfcCount!:number;
  public requestCanceledCount!:number;
  public requestDeclinedCount!:number;

  constructor(init:ListCompaniesForSubmission) {
    ObjectUtils.assignLiteralFields(this, init);
  }
  public get todoCount():number {
    return Number(this.requestedCount) + Number(this.rfcCount);
  }
  public get abortCount():number {
    return Number(this.requestCanceledCount) + Number(this.requestDeclinedCount);
  }
}
