












































import { Component, Prop} from 'vue-property-decorator';

import InputSearch from '@/views/label/companies/components/InputSearch.vue';
import {IFindOption} from "@/repositories/repository-base";

import ListPageBase from "@/views/label/companies/ListPageBase";

import {SpecShareStatusForDisplay} from "@/entities/specs/spec-share-entity";
import SpecShareForSubmitterRepository, {ListCompaniesForSubmission} from "@/repositories/spec/company/spec-share-for-submitter-repository";

@Component({
  components: {
    InputSearch
  }
})
export default class extends ListPageBase {
  private readonly Status = SpecShareStatusForDisplay;

  protected async find(searchText, opt:IFindOption) {
    return await (new SpecShareForSubmitterRepository(this.companyId)).listCompaniesForSubmission(searchText, opt);
  }

  private get totalTodoCount(): number {
    if(!this.dataList) return 0;
    return this.dataList.data.reduce((prev:number, current:ListCompaniesForSubmission) => prev + current.todoCount, 0);
  }
}
