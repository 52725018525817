























































import { Component, Vue, Prop } from 'vue-property-decorator';
import SpecSubmissionEntity, {
  ISpecSubmissionRequest,
  ValidatorRules as SubmissionValidatorRules
} from "@/entities/specs/spec-submission-entity";
import {ValidateWithScroll} from "@/utils/validation-handler";
import SpecEntity from "@/entities/specs/spec-entity";
import { help as PopoverText } from '@/lang/help/spec-create';
import {HideableFieldsDict} from "@/entities/specs/spec-ingredient-entity";
import {SpecShareEntity} from "@/entities/specs/spec-share-entity";
import uniq from "lodash/uniq";
import SubmissionListPanel from "@/views/spec/components/SubmissionListPanel.vue";
@Component({
  components: {SubmissionListPanel}
})
export default class extends Vue {
  @Prop({required: true}) private companyId!:number;
  @Prop({required: true, type: String}) private title!:string;
  @Prop({required: true, type: Object}) private specShare!:SpecShareEntity;
  @Prop({required: true}) private selectedSpec!:SpecEntity|null;

  @Prop({required: false, default: null}) private lastSubmission!:SpecSubmissionEntity|null;
  @Prop({required: false, default: null}) private error!:string|null;
  @Prop({required: false, default: false}) private isOnCreateView!:boolean;

  private readonly HideableFieldsOnSubmit = HideableFieldsDict;
  private readonly PopoverText = PopoverText;
  private readonly SubmissionValidatorRules = SubmissionValidatorRules;

  private data:ISpecSubmissionRequest = {
    specShareId: this.specShare.id,
    price: null,
    message: { operatorName: this.$auth.user.name }
  } as ISpecSubmissionRequest;

  private visible:boolean = true;

  private async created() {
    if (this.lastSubmission) {
      this.data.price = this.lastSubmission.price;
    }
  }

  private uniqueErrors(): string[] | null {
    if(!this.error) return null;
    return uniq(this.error.split('\n'));
  }

  private async submit() {
    if (!this.selectedSpec) return;

    if (!(await ValidateWithScroll(this.$refs.submissionForm as any))) return;

    this.$emit('submit', {
      data: this.data,
      selectedSpec: this.selectedSpec,
    })
  }
}
