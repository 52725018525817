























import { Component, Vue, Prop } from 'vue-property-decorator';
import SpecRepository, {LatestSpecsForSubmission} from "@/repositories/spec/company/spec-repository";
import SpecEntity from "@/entities/specs/spec-entity";
import { help as PopoverText } from '@/lang/help/spec-create';
import SubmissionModalBase from "./SubmissionModalBase.vue";
import {SpecShareEntity} from "@/entities/specs/spec-share-entity";
import {formatDate} from '@/utils/date-utils';

@Component({
  components: {SubmissionModalBase}
})
export default class extends Vue {
  // 依頼への新規提出
  @Prop({required: true}) private companyId!:number;
  @Prop({required: true}) private specShare!:SpecShareEntity;
  @Prop({required: false, default: null}) private lastSubmissionSpecId!:number|null;
  @Prop({required: false, default: null}) private error!:string|null;

  private readonly PopoverText = PopoverText;

  private specList:LatestSpecsForSubmission[]|null = null;
  private selectedSpecId:number|null = null;
  private selectedSpec:SpecEntity|null = null;

  private created() {
    (new SpecRepository(this.companyId)).listLatestSpecsForSubmission().then(list => {

      this.specList = list.map(d => {
        return {
          ...d,
          name: d.name + {
            'latest_draft': '　（正規版無し）',
            'latest_published': `　（${formatDate(d.createdAt, 'yy/MM/dd')}正規版）`,
            'non_latest_published': `　（${formatDate(d.createdAt, 'yy/MM/dd')}正規版）※未承認の最新下書き有り`,
          }[d.type],
          _option_disabled: d.type === 'latest_draft'
        }
      });
    });

    if(this.lastSubmissionSpecId) {
      this.selectedSpecId = this.lastSubmissionSpecId;
      this.onSubmissionSpecSelected(this.lastSubmissionSpecId);
    }
  }

  private async onSubmissionSpecSelected(specId:number) {
    this.selectedSpec = await (new SpecRepository(this.companyId)).find(specId);
    this.$emit('change');
  }
}
