












import { Component, Vue, Prop } from 'vue-property-decorator';
import { help as PopoverText } from '@/lang/help/spec-create';

@Component
export default class extends Vue {
  @Prop({required: true}) private errors!:string[];

  private readonly PopoverText = PopoverText;
}
