























































import { Component, Vue, Prop } from 'vue-property-decorator';

import {formatDate} from '@/utils/date-utils';
import LoadingHandler from "@/utils/loading-handler";
import { SpecSimple } from "@/repositories/spec/company/spec-meta-repository";
import SpecRepository from '@/repositories/spec/company/spec-repository';
import SubmissionListPanel from "@/views/spec/components/SubmissionListPanel.vue";
import { ReceiveListItem } from "@/repositories/spec/company/spec-share-for-receiver-repository";

type SpecListType = SpecSimple & { label: string; _option_disabled: boolean };
@Component({
  components: {SubmissionListPanel}
})
export default class extends Vue {
  @Prop({required: true}) private companyId!:number;
  @Prop({required: true}) private item!: ReceiveListItem;
  private visible:boolean = true;

  private selectedSpecId:number = 0;
  private specList:SpecListType[]|null = null;

  private initialized: boolean = false;

  private get canEdit() {
    return this.$auth.user.isCompanyEditable;
  }

  private async created() {
    await LoadingHandler(async () => {
      await Promise.all([
        (new SpecRepository(this.companyId)).findMeta(this.item.lastSubmissionSpecId!).then(meta => {
          const specList = meta.specs;
          specList.sort((a,b) => b.id - a.id);
          this.specList = specList.map((s: SpecSimple) => {
            return {
              ...s,
              label: `${s.name}　（${this.formatDate(s.createdAt)} ${this.$t(s.isPublished ? '正規版' : '下書き分')}）`,
              _option_disabled: !s.isPublished
            };
          });
          const firstPublishedSpec = specList.find(s => s.isPublished);
          if (firstPublishedSpec) {
            this.selectedSpecId = firstPublishedSpec.id;
          }
        }),
      ]);

      this.initialized = true;
    });
  }

  private get selectedSpec(): SpecSimple {
    return this.specList!.find(s => s.id === this.selectedSpecId)!;
  }

  private formatDate(val, format = 'yyyy/MM/dd HH:mm'): string {
    return formatDate(val, format);
  }
}
