





















































































































































import {Component, Prop} from 'vue-property-decorator';

import InputSearch from '@/views/label/companies/components/InputSearch.vue';
import {IFindOption} from "@/repositories/repository-base";

import ListPageBase from "@/views/label/companies/ListPageBase";

import {
  ShareList, ShareResponse, StatusTotalCountForDisplay,
} from '@/repositories/spec/company/spec-share-repository';
import LoadingHandler from "@/utils/loading-handler";
import HistoryModal from "@/views/spec/companies/components/HistoryModal.vue";
import SpecShareMessage, {ISpecShareMessageRequest} from "@/entities/specs/spec-share-message-entity";
import {
  convertFromSpecShareForDisplay, SpecShareEntity,
  SpecShareStatus, SpecShareStatusForDisplay,
  STATUS_DICT_FOR_SUBMITTER
} from "@/entities/specs/spec-share-entity";
import SpecSubmissionEntity, {ISpecSubmissionRequest,} from "@/entities/specs/spec-submission-entity";

import RequestModal from "./components/RequestModal.vue";
import RfcModal from "./components/RfcModal.vue";
import SubmissionModalWithSelection from "./components/SubmissionModalWithSelection.vue";
import SubmissionModalWithSpec from "./components/SubmissionModalWithSpec.vue";
import DeclineModal from "./components/DeclineModal.vue";
import SpecEntity from "@/entities/specs/spec-entity";
import SpecRepository from "@/repositories/spec/company/spec-repository";
import SpecShareForSubmitterRepository from "@/repositories/spec/company/spec-share-for-submitter-repository";
import {HandledApiError, HttpStatusCode} from "@/bootstraps/error-handler";
import uniqBy from "lodash/uniqBy";
import ModifySubmissionSpecModal from "@/views/spec/companies/submissions/components/ModifySubmissionSpecModal.vue";
import {SpecSimple, SpecSimpleEntity} from "@/repositories/spec/company/spec-meta-repository";

@Component({
  components: {
    ModifySubmissionSpecModal,
    RequestModal,
    SubmissionModalWithSelection,
    RfcModal,
    SubmissionModalWithSpec,
    DeclineModal,
    InputSearch,
    HistoryModal
  }
})
export default class extends ListPageBase {
  @Prop({required: false, default: 'all'}) private status!:SpecShareStatusForDisplay;

  private readonly Status = SpecShareStatusForDisplay;
  private readonly STATUS_DICT_FOR_SUBMITTER = STATUS_DICT_FOR_SUBMITTER;

  private requestModal = { visible: false, item: null as ShareList|null };
  private submissionModalForRequest = {
    visible: false,
    specShare: null as SpecShareEntity|null,
    data: null as ShareList|null,
  }
  private rfcModal = { visible: false, item: null as ShareList|null };
  private submissionModalForRfc:{
    specShare: SpecShareEntity,
    lastSubmission: SpecSubmissionEntity,
    spec: SpecEntity,
    error: string,
  }|null = null;
  private modifySubmissionModal:{
    spec: SpecSimple,
    specShareId: number,
    error: string|null,
    onBack: () => void,
  }|null = null;

  private historyModal = { visible: false, messages: null as SpecShareMessage[]|null };
  private declineModal = { visible: false, specShareId: 0, message: { operatorName: this.$auth.user.name, comment: '' } as ISpecShareMessageRequest };

  private receiverName:string|null = null;

  private get queryReceiverId(): string|null {
    return this.getCurrentGETParameters().receiverId;
  }
  private get querySpecShareIdToSubmit(): number |undefined {
    return this.$route.query.specShareIdToSubmit ? Number(this.$route.query.specShareIdToSubmit) : undefined;
  }

  private readonly statusTagClassDict = {
    [SpecShareStatus.REQUEST_CANCELED]: 'info',
    [SpecShareStatus.REQUEST_DECLINED]: 'info',
    [SpecShareStatus.REQUESTED]: 'danger',
    [SpecShareStatus.ACCEPTED]: 'info',
    [SpecShareStatus.RFC]: 'danger',
    [SpecShareStatus.SUBMITTED]: 'success',
  }

  private countsByStatus:StatusTotalCountForDisplay[]|null = null;

  protected getRepository() {
    return (new SpecShareForSubmitterRepository(this.companyId));
  }

  protected async onCreating() {
    if (this.queryReceiverId) {
      this.receiverName = '...';
      this.getRepository().findReceiver(this.queryReceiverId).then(receiver => {
        this.receiverName = receiver.name;
      });
    }
  }
  protected async onCreated(): Promise<void> {
    if (this.querySpecShareIdToSubmit) {
      const target = this.dataList!.data.find((d: ShareList) => d.specShareId === this.querySpecShareIdToSubmit)
      if (target) {
        this.showRequestModal(target)
      }
    }
  }

  protected async find(searchText, opt:IFindOption) {
    const statuses = convertFromSpecShareForDisplay(this.status);
    const res: ShareResponse = await this.getRepository().list(searchText, statuses, this.queryReceiverId, opt);
    this.countsByStatus = res.counts;
    return res.data;
  }

  private getStatusCount(status: SpecShareStatusForDisplay) {
    return this.countsByStatus!.find(s => s.status === status)!.total;
  }
  private get isTransferRequestEnabled() {
    return this.selectedRows.length > 0
      && uniqBy(this.selectedRows, (d: ShareList) => d.requestMetaId).length === 1;
  }
  private transferRequests() {
    if(!this.isTransferRequestEnabled) return;
    const requestIdList = this.selectedRows.map((d: ShareList) => d.requestId);
    this.$router.push({name: 'share-request.transfer', query: { requests: requestIdList.join(',') }});
  }

  private get declineEnabled() {
    return this.selectedRows.length === 1 && this.selectedRows[0].canAbort;
  }
  private showHistoryModal(data:ShareList) {
    this.historyModal.messages = data.messages;
    this.historyModal.visible = true;
  }

  private showRequestModal(data:ShareList) {
    this.requestModal.item = data;
    this.requestModal.visible = true;
  }
  private showSubmissionModalForRequest(data:ShareList) {
    LoadingHandler(async () => {
      this.requestModal.visible = false;
      this.submissionModalForRequest.data = data;
      this.submissionModalForRequest.specShare = await (new SpecShareForSubmitterRepository(this.companyId)).findSpecShare(data.specShareId);
      this.submissionModalForRequest.visible = true;
    });
  }
  private onBackFromSubmissionForRequest() {
    this.requestModal.visible = true;
    this.submissionModalForRequest.visible = false;
  }
  private async onSubmitForRequest(data:{data: ISpecSubmissionRequest, selectedSpec:SpecEntity}) {
    try {
      await LoadingHandler(async () => {
        await this.getRepository().saveSpecSubmissionWithSelectingSpec(data.data, data.selectedSpec.id);
      });
    } catch(error) {
      if (error instanceof HandledApiError && error.httpStatus === HttpStatusCode.Raw) {
        this.modifySubmissionModal = {
          spec: data.selectedSpec,
          specShareId: data.data.specShareId,
          error: error.message,
          onBack: () => {
            this.modifySubmissionModal = null;
            this.submissionModalForRequest.visible = true;
          },
        };
        this.submissionModalForRequest.visible = false;
        return;
      }
      throw error;
    }

    LoadingHandler(async () => {
      await this.load();
    }).then(() => {
      this.$message({type: 'success', message: this.$t('xを提出しました', [ data.selectedSpec.name] )});
      this.submissionModalForRequest.visible = false;
    });
  }

  private showRfcModal(data:ShareList) {
    this.rfcModal.item = data;
    this.rfcModal.visible = true;
  }
  private onRfcModalEdit(data: { item: ShareList, spec: SpecSimple} ) {
    this.modifySubmissionModal = {
      spec: data.spec,
      specShareId: data.item.specShareId,
      error: null,
      onBack: () => {
        this.modifySubmissionModal = null;
        this.rfcModal.visible = true;
      },
    };
    this.rfcModal.visible = false;
  }
  private showSubmissionModalForRfc(selectedSpecId) {
    LoadingHandler(async () => {
      const shareId = this.rfcModal.item!.specShareId;

      return Promise.all([
        (new SpecRepository(this.companyId)).find(selectedSpecId),
        // パフォーマンスに影響あるならsubmissionだけ取得するようにする
        this.getRepository().findSpecShare(shareId)
      ]).then(([spec, specShare]) => {
        this.submissionModalForRfc = {
          specShare: specShare,
          spec: spec,
          lastSubmission: specShare.latestSubmission!,
          error: '',
        };
      });
    });
  }
  private async onSubmitForRfc(data:{data: ISpecSubmissionRequest, selectedSpec:SpecEntity}) {
    try {
      await LoadingHandler(async () => {
        await this.getRepository().saveSpecSubmissionWithSelectingSpec(data.data, data.selectedSpec.id);
      });
    } catch (error) {
      if (error instanceof HandledApiError && error.httpStatus === HttpStatusCode.Raw) {
        this.submissionModalForRfc!.error = error.message;
        return;
      }
      throw error;
    }

    LoadingHandler(async () => {
      await this.load();
    }).then(() => {
      this.$message({type: 'success', message: this.$t('xを提出しました', [ data.selectedSpec.name] )});
      this.submissionModalForRfc = null;
      this.rfcModal.visible = false;
    });
  }
  private onSpecChangedForRfc() {
    if (this.submissionModalForRfc) {
      this.submissionModalForRfc!.error = '';
    }
  }

  private showDeclineModal() {
    const row:ShareList = this.selectedRows[0];
    this.declineModal.specShareId = row.specShareId;
    this.declineModal.visible = true;
  }
  public declineRequest(specShareId:number, message: ISpecShareMessageRequest) {
    LoadingHandler(async () => {
      await this.getRepository().setStatusAsRequestDeclined(specShareId, message);
      this.declineModal.visible = false;
      await this.load();
      this.$message({type: 'success', message: this.$t(`依頼を取り下げました`)});
    });
  }

  public row(scope: { row: ShareList}): ShareList {
    return scope.row;
  }
}
