









































import { Component, Vue, Prop } from 'vue-property-decorator';
import {ShareList} from "@/repositories/spec/company/spec-share-repository";

@Component
export default class extends Vue {
  @Prop({required: true}) private item!:ShareList;
  private visible:boolean = true;

  private get canEdit() {
    return this.$auth.user.isCompanyEditable;
  }
}
