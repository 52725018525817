


































import { Component, Vue, Prop } from 'vue-property-decorator';

import LoadingHandler from "@/utils/loading-handler";
import SpecMetaRepository, {
  IAcceptedSubmissionResponse,
  SpecSimple
} from "@/repositories/spec/company/spec-meta-repository";
import SubmissionListPanel from "@/views/spec/components/SubmissionListPanel.vue";
import SubmissionRequiredErrorPanel
  from "@/views/spec/companies/submissions/components/SubmissionRequiredErrorPanel.vue";
import uniq from "lodash/uniq";
@Component({
  components: {SubmissionRequiredErrorPanel, SubmissionListPanel}
})
export default class extends Vue {
  @Prop({required: true}) private companyId!:number;
  @Prop({required: true}) private specShareId!:number;
  @Prop({required: true}) private spec!:SpecSimple;
  @Prop({required: true}) private error!:string;
  @Prop({required: true}) private onBack!:() => void;

  private visible = false;

  private acceptedSubmissions: IAcceptedSubmissionResponse[] = [];

  private async created() {
    await LoadingHandler(async () => {
      this.acceptedSubmissions = await (new SpecMetaRepository(this.companyId)).getAcceptedSubmissions(this.spec.specMetaId);

      if (this.acceptedSubmissions.length === 0 && !this.hasError) {
        this.forward();
      }
      this.visible = true;
    });
  }

  private get uniqueErrors(): string[] | null {
    if (!this.error) return null;
    return uniq(this.error.split('\n'));
  }

  private get hasError(): boolean {
    return !!this.error;
  }
  private get title(): string {
    return this.hasError ?
      this.$t('提出情報の不足') :
      this.$t('規格書を修正して提出');
  }
  private get description(): string {
    return this.hasError ?
      this.$t('提出しようとした規格書は、依頼元が必要としている情報が不足しています。この規格書を提出したい場合、修正した上で提出してください。') :
      this.$t('この規格書を更新すると、既存の提出先に対しても規格書の更新が配信される場合があります。');
  }

  private get canEdit() {
    return this.$auth.user.isCompanyEditable;
  }
  private forward() {
    this.$router.push({name: 'submission.revise', params: { specShareId: this.specShareId, specId: this.spec.id } as any});
  }

  private back() {
    this.onBack();
    this.$emit('back');
  }
}
