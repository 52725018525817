

















import { Component, Vue, Prop } from 'vue-property-decorator';
import SpecEntity from "@/entities/specs/spec-entity";
import SubmissionModalBase from "./SubmissionModalBase.vue";
import SpecSubmissionEntity from "@/entities/specs/spec-submission-entity";
import {SpecShareEntity} from "@/entities/specs/spec-share-entity";
import SubmissionListPanel from "@/views/spec/components/SubmissionListPanel.vue";

@Component({
  name: 'SubmissionModalWithSpec',
  components: {SubmissionListPanel, SubmissionModalBase}
})
export default class extends Vue {
  // 差戻し、または作成して提出
  @Prop({required: true}) private companyId!:number;
  @Prop({required: true, type: Object}) private specShare!:SpecShareEntity;
  @Prop({required: true, type: Object}) private spec!:SpecEntity;
  @Prop({required: true}) private lastSubmission!:SpecSubmissionEntity|null;
  @Prop({required: false, default: null}) private error!:string|null;
  @Prop({required: false, default: false}) private isOnCreateView!:boolean;

  private selectedSpec:SpecEntity|null = null;

  private created() {
    this.selectedSpec = this.spec;
  }

}
