
























import { Component, Vue, Prop } from 'vue-property-decorator';
import {ISpecShareMessageRequest} from "@/entities/specs/spec-share-message-entity";

@Component
export default class extends Vue {
  @Prop({required: true}) private message!:ISpecShareMessageRequest;
  private visible:boolean = true;
}
